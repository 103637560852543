import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import useAuth from './Authorization/useAuth'
import { ProtectedRoute } from './Authorization/ProtectedRoute';
import { AppHeader } from './Header/AppHeader';

const ProductDashboard = lazy(() => import('./Pages/ProductDashboard/ProductDashboard'));
const Login = lazy(() => import('./Authorization/Login'));
const PasswordReset = lazy(() => import('./Authorization/PasswordReset'));
const PasswordResetRequest = lazy(() => import('./Authorization/PasswordResetRequest'));
const LotDetails = lazy(() => import('./Pages/LotDetails/LotDetails'));
const LotGraphs = lazy(() => import('./Pages/LotGraphs/LotGraphs'));
const ErrorPage = lazy(() => import('./Pages/ErrorPage'));

/**
 * @description App constant is the functional component for the internal tool header and body
 * Contains routes for all the different pages
 * App is wrapped with Router component in index.tsx which allows for Route functionality
 * 
 * @returns {React.FC} App with header and body with different routes
 */
const App: React.FC = () => {
  const { loggedIn, setLoggedIn, loading } = useAuth();

  // this state makes sure it only progresses to the sites content once it is finished authenticating
  if(loading){return <div>Loading...</div>}

  return (
    <div style={{display: 'flex', flexDirection:'column', height:'100vh'}}>
      <AppHeader loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/login" element={<Login updateLoginStatus={setLoggedIn}/>} />
          <Route path="/request-password-reset" element={<PasswordResetRequest />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/" element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          {/* Each Route that requires protection is individually wrapped */}
          <Route path="/dashboard" element={<ProtectedRoute isAuthenticated={loggedIn}><ProductDashboard /></ProtectedRoute>} />
          <Route path="/lot-details/:lotnum" element={<ProtectedRoute isAuthenticated={loggedIn}><LotDetails /></ProtectedRoute>} />
          <Route path="/lot-graphs" element={<ProtectedRoute isAuthenticated={loggedIn}><LotGraphs /></ProtectedRoute>} />
          <Route path="*" element={<ErrorPage/>} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
